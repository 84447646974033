import { Popup as PopupType } from "@/types/popup";
import PubSub, { SubscriptionListener, useSubscribe } from "@lib/pubsub";
import jsonHash from "@lib/tools/json-hash";
import objectEntries from "@lib/tools/object-entries";
import clsx from "clsx";
import React, { createRef, isValidElement, ReactElement, RefObject, useCallback, useRef, useState } from "react";
import { type PopupItemProps, HandledRef, PopupItem } from "./PopupItem";
/**
 * if the value is a valid React element, it serializes its props
 */
const fixReplacements = (replacements?: Record<string, unknown>) => {
  if (!replacements) return null;
  return Object.fromEntries(objectEntries(replacements).map(([key, value]) => {
    return [key, isValidElement(value) ? JSON.stringify(value?.props) : value];
  }));
};

/**
 * Generates a hash for a given popup object, including its replacements.
 * If a replacement value is a valid React element, it serializes its props.
 *
 * @param popup - The popup object to hash.
 * @returns The generated hash for the popup.
 */
function getHash({
  replacements,
  ...popup
}: PopupType) {
  return jsonHash({
    replacements: replacements ? fixReplacements(replacements) : undefined,
    ...popup
  });
}
type Refs = Record<string, RefObject<HandledRef>>;
export default function Popup() {
  const refs = useRef<Refs>({});
  const [list, setList] = useState<ReactElement<PopupItemProps>[]>([]);
  const cleanup = useCallback((id: string) => {
    delete refs.current[id];
    const isAllComplete = Object.values(refs.current).every(ref => ref.current?.isComplete);
    if (isAllComplete) {
      setList([]);
    }
  }, []);
  const makePopup = useCallback(async (props: PopupType) => {
    const id = props.id || (await getHash(props));
    const hasType = Number.isInteger(props?.type);
    if (!id) return null;
    if (!hasType) return null;
    const isDuplicate = refs.current?.[id] ? id in refs.current : false;
    if (isDuplicate) return null;
    const ref = createRef<HandledRef>();
    refs.current[id] = ref;
    return <PopupItem {...props} id={id} onComplete={cleanup} ref={ref} /> as ReactElement<PopupItemProps>;
  }, [cleanup]);
  const subFn: SubscriptionListener<PopupType> = useCallback(async (_, data) => {
    const popup = await makePopup(data);
    if (!popup) {
      refs.current?.[data.id]?.current?.resetTimer?.();
      return null;
    }
    setList(old => [...old, popup]);
  }, [makePopup]);
  useSubscribe<PopupType>(PubSub.EVENTS.POPUP, subFn);
  return <div className={clsx("fixed top-[68px] right-[calc(300px+2%)] h-[100vh] w-0", "z-5004 flex flex-col gap-2", "mt-4")} data-sentry-component="Popup" data-sentry-source-file="Popup.tsx">
      {list}
    </div>;
}