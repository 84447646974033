import { Popup, TYPES } from "@/types/popup";
import useLanguageLegacy from "@hooks/useLanguage";
import useWallet from "@hooks/useWallet";
import CurrencyIcons from "@items/CurrencyIcons";
import ValueDisplay from "@items/ValueDisplay";
import ValueIcons from "@items/ValueIcons";
import st from "@styles/components/popup.module.scss";
import clsx from "clsx";
import gsap from "gsap";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
const type: TYPES = {
  NEGATIVE: 0,
  POSITIVE: 1,
  CAUTION: 2
};
const noReplacements = {};
const replace = ({
  currency,
  replacements,
  raw = false
}: ReplaceProps): Record<string, React.ReactNode | null> => {
  try {
    const replaceKey = (key: string) => {
      switch (key) {
        case "CURRENCY_AMOUNT":
        case "TOKEN_AMOUNT":
          {
            const amount = replacements?.["CURRENCY_AMOUNT"] || replacements?.[key];
            return <ValueDisplay amount={+amount || 0} currencyCode={replacements?.CURRENCY} size={"small"} classType={"inline-popup"} raw={raw} />;
          }
        case "CALENDAR_REWARD":
          return <>
              <br />
              <ValueDisplay amount={Number(replacements[key])} currencyCode={replacements?.CURRENCY} size={"small"} classType={"inline-popup"} raw={raw} />
            </>;
        case "INSTANT_REWARD":
          return <>
              <div className={st["horizontal-line-container"]}>
                <div className={st["horizontal-line"]}></div>
              </div>

              <ValueDisplay amount={Number(replacements[key])} currencyCode={replacements?.CURRENCY} size={"small"} classType={"inline-popup"} raw={raw} />
            </>;
        case "CRYPTO_AMOUNT":
          return <span className={st["value-display-container"]}>
              <span>
                <ValueIcons code={replacements["CRYPTO_CODE"]} size={"small"} />
              </span>
              {Number(replacements[key])}
            </span>;
        case "TOKEN_ICON":
          return <span className={st["value-display-container"]}>
              <span className={st["token-content"]}>
                <CurrencyIcons code={currency} size={"small"} />
              </span>
            </span>;
        default:
          return replacements[key];
      }
    };
    const additions = replacements && typeof replacements === "object" ? Object.keys(replacements).reduce((acc, key) => ({
      ...acc,
      [key]: replaceKey(key)
    }), {} as Record<string, React.ReactNode | null>) : {};
    const result = {
      ...replacements,
      ...additions
    };
    return result;
  } catch {
    return noReplacements;
  }
};
const SVG_GREEN_CHECK = <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 512 512">
    <path d="M256 32C132.3 32 32 132.3 32 256s100.3 224 224 224 224-100.3 224-224S379.7 32 256 32zm114.9 149.1L231.8 359.6c-1.1 1.1-2.9 3.5-5.1 3.5-2.3 0-3.8-1.6-5.1-2.9-1.3-1.3-78.9-75.9-78.9-75.9l-1.5-1.5c-.6-.9-1.1-2-1.1-3.2 0-1.2.5-2.3 1.1-3.2.4-.4.7-.7 1.1-1.2 7.7-8.1 23.3-24.5 24.3-25.5 1.3-1.3 2.4-3 4.8-3 2.5 0 4.1 2.1 5.3 3.3 1.2 1.2 45 43.3 45 43.3l111.3-143c1-.8 2.2-1.4 3.5-1.4 1.3 0 2.5.5 3.5 1.3l30.6 24.1c.8 1 1.3 2.2 1.3 3.5.1 1.3-.4 2.4-1 3.3z" fill="#4BAE4F"></path>
  </svg>;
const SVG_NEGATIVE = <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
    <path fill="#E24C4B" d="M13 0a13 13 0 1 0 0 26 13 13 0 0 0 0-26Zm-1 7a1 1 0 0 1 2 0v7a1 1 0 0 1-2 0Zm1 13a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z" />
  </svg>;
const SVG_CLOSE = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.40994 6.99994L12.7099 2.70994C12.8982 2.52164 13.004 2.26624 13.004 1.99994C13.004 1.73364 12.8982 1.47825 12.7099 1.28994C12.5216 1.10164 12.2662 0.99585 11.9999 0.99585C11.7336 0.99585 11.4782 1.10164 11.2899 1.28994L6.99994 5.58994L2.70994 1.28994C2.52164 1.10164 2.26624 0.99585 1.99994 0.99585C1.73364 0.99585 1.47824 1.10164 1.28994 1.28994C1.10164 1.47825 0.995847 1.73364 0.995847 1.99994C0.995847 2.26624 1.10164 2.52164 1.28994 2.70994L5.58994 6.99994L1.28994 11.2899C1.19621 11.3829 1.12182 11.4935 1.07105 11.6154C1.02028 11.7372 0.994141 11.8679 0.994141 11.9999C0.994141 12.132 1.02028 12.2627 1.07105 12.3845C1.12182 12.5064 1.19621 12.617 1.28994 12.7099C1.3829 12.8037 1.4935 12.8781 1.61536 12.9288C1.73722 12.9796 1.86793 13.0057 1.99994 13.0057C2.13195 13.0057 2.26266 12.9796 2.38452 12.9288C2.50638 12.8781 2.61698 12.8037 2.70994 12.7099L6.99994 8.40994L11.2899 12.7099C11.3829 12.8037 11.4935 12.8781 11.6154 12.9288C11.7372 12.9796 11.8679 13.0057 11.9999 13.0057C12.132 13.0057 12.2627 12.9796 12.3845 12.9288C12.5064 12.8781 12.617 12.8037 12.7099 12.7099C12.8037 12.617 12.8781 12.5064 12.9288 12.3845C12.9796 12.2627 13.0057 12.132 13.0057 11.9999C13.0057 11.8679 12.9796 11.7372 12.9288 11.6154C12.8781 11.4935 12.8037 11.3829 12.7099 11.2899L8.40994 6.99994Z" fill="rgba(255, 255, 255, 0.75)" />
  </svg>;
export const DEFAULT_ANIMATION_DURATION = 3000;
export const IN_OUT_ANIMATION_DURATION = 1000 / 3;
export const ANIMATION_DURATION = (duration = DEFAULT_ANIMATION_DURATION) => IN_OUT_ANIMATION_DURATION * 2 + duration;
export type HandledRef = {
  element: HTMLButtonElement;
  resetTimer: () => void;
  id: string;
  isComplete: boolean;
};
type GetTimelineProps = {
  id: string;
  onComplete: () => void;
  element: HTMLButtonElement;
  bar: HTMLDivElement;
  duration: number;
};
const getTimeline = ({
  id,
  onComplete,
  element,
  bar,
  duration
}: GetTimelineProps) => gsap.timeline({
  id,
  paused: true,
  onComplete
}).set(element, {
  position: "unset",
  x: 500,
  alpha: 1,
  duration: IN_OUT_ANIMATION_DURATION / 1000
}).to(element, {
  x: 0,
  ease: "back.out",
  duration: IN_OUT_ANIMATION_DURATION / 1000
}).to(bar, {
  duration: (ANIMATION_DURATION(duration) - IN_OUT_ANIMATION_DURATION) / 1000,
  width: 0,
  ease: "sine.out"
}).set(element, {
  pointerEvents: "none"
}).to(element, {
  duration: IN_OUT_ANIMATION_DURATION / 500,
  x: 500,
  ease: "back.inOut",
  height: 0,
  overflow: "hidden",
  marginTop: 0,
  marginBottom: 0,
  paddingTop: 0,
  paddingBottom: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0,
  display: "none"
});
export const PopupItem = forwardRef<HandledRef, PopupItemProps>((props, ref) => {
  const {
    duration,
    onComplete,
    id
  } = props;
  const barRef = useRef<HTMLDivElement>(null);
  const localRef = useRef<HTMLButtonElement>(null);
  const [timeline, setTimeline] = useState<ReturnType<typeof gsap.timeline>>(null);
  const {
    activeRate
  } = useWallet();
  const [isComplete, setIsComplete] = useState(false);
  const L = useLanguageLegacy();
  const resetTimer = useCallback(() => {
    timeline?.progress(0.07);
  }, [timeline]);
  useImperativeHandle(ref, () => ({
    element: localRef.current,
    isComplete,
    resetTimer,
    id
  }));
  useEffect(() => {
    if (!isComplete) return;
    onComplete?.(id);
    timeline?.kill();
  }, [id, isComplete, onComplete, timeline]);
  useEffect(() => {
    if (!localRef.current || !barRef.current) return;
    const timeline = getTimeline({
      id,
      onComplete: () => setIsComplete(true),
      element: localRef.current,
      bar: barRef.current,
      duration: duration
    });
    setTimeline(timeline);
    timeline.play();
    return () => {
      timeline.kill();
    };
  }, [duration, id]);
  const popupDetails = useMemo(() => {
    let msgCode: string;
    let type: TYPES[keyof TYPES];
    if (props.result) {
      msgCode = props.result;
      type = 1;
    } else if (props.error) {
      msgCode = props.error;
      type = 2;
    } else {
      msgCode = props.code;
      type = props.type;
    }
    return {
      msg: msgCode,
      extraMsg: props?.msg ? props.msg : "",
      type: type,
      replacements: replace({
        currency: activeRate.code,
        replacements: props?.replacements,
        raw: props?.raw || false
      }) || noReplacements
    };
  }, [activeRate.code, props.code, props.error, props.msg, props?.raw, props?.replacements, props.result, props.type]);
  const hasIcon = popupDetails.type === type.POSITIVE || popupDetails.type === type.NEGATIVE;
  const onMouseOver = useCallback(() => timeline?.pause(), [timeline]);
  const onMouseLeave = useCallback(() => timeline?.play(), [timeline]);
  const onClick = useCallback(() => timeline?.progress(0.94), [timeline]);
  return <button ref={localRef} className={clsx(st["popup-content"], st[`popup-type-${popupDetails.type}`], "popup-class", "cursor-pointer")} aria-label="Close popup notification" onMouseOver={onMouseOver} onFocus={onMouseOver} onMouseLeave={onMouseLeave} onBlur={onMouseLeave} onTouchStart={onMouseOver} onTouchEnd={onMouseLeave} onClick={onClick}>
        <div className="flex justify-between gap-2.5 mb-5 w-full items-start">
          {hasIcon ? <div className="min-w-4 w-4 [&>svg]:w-full [&>svg]:h-full translate-y-0.5">
              {popupDetails.type === type.POSITIVE ? SVG_GREEN_CHECK : SVG_NEGATIVE}
            </div> : null}

          <span className="text-sm text-white text-left font-normal" data-span>
            {L(popupDetails?.msg, popupDetails?.replacements)}

            {popupDetails?.extraMsg}
            {props?.value ? <div className={st["value-display-container"]}>
                <ValueDisplay amount={props?.value} size="small" classType="inline-popup-icon" />
              </div> : null}
          </span>

          <div className="ml-auto [&>svg]:w-2.5 [&>svg]:h-2.5">{SVG_CLOSE}</div>
        </div>

        <div className={st["loading-bar"]}>
          <div ref={barRef} className={st["bar"]} />
        </div>
      </button>;
});
PopupItem.displayName = "PopupItem";
export type PopupItemProps = Popup & {
  onComplete?: (id: string) => void;
  id: string;
};
type ReplaceProps = {
  currency: string;
  replacements: Record<string, string>;
  raw?: boolean;
};